import * as React from 'react';
import { Link, graphql } from 'gatsby';
import cn from 'classnames';
import { ProductCard } from './ProductCard';

type Props = {
  readonly data: GatsbyTypes.CategoryRow_DataFragment;
  readonly className?: string;
};

export const CategoryRow = ({ data, className }: Props) => (
  <div className={cn('mf-container py-12', className)}>
    <div className="flex items-center mb-6">
      <div className="text-mf-black text-2xl font-bold leading-7 mr-3">{data.name}</div>
      <Link to={data.pageUrl} className="flex items-center text-mf-orange leading-7">
        Перейти в <span className="lowercase ml-1">{data.name}</span>
      </Link>
    </div>
    <div className="mf-pc-grid">
      {data.products.map((p) => (
        <div key={p.rowId} className="mf-category-row-item">
          <ProductCard data={p} />
        </div>
      ))}
    </div>
  </div>
);

export const query = graphql`
  fragment CategoryRow_data on Api_Category {
    name
    pageUrl
    products(first: 15) {
      rowId
      ...ProductCard_data
    }
  }
`;
