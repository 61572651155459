import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CategoryRow } from './CategoryRow';

export const Recommendations = () => {
  const data = useStaticQuery<GatsbyTypes.RecommendationsQuery>(
    graphql`
      query RecommendationsQuery {
        api {
          viewer {
            recommendations {
              ...CategoryRow_data
            }
          }
        }
      }
    `,
  );

  return <CategoryRow data={{ ...data.api.viewer.recommendations, pageUrl: '/recommendations' }} />;
};
