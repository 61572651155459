import * as React from 'react';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Recommendations } from 'components/Recommendations';

export default () => (
  <Layout>
    <SEO title="Натуральные продукты" />
    <Recommendations />
  </Layout>
);
